import React, { useMemo } from "react";
import { useParams } from "react-router-dom";
import { Container } from "react-bootstrap";

import Layout from "../layout";
import Header from "../components/Header";
import Menu from "../components/Admin/Menu";
import GameTable from "../components/Admin/GameTable";
import ManageCredits from "../components/Admin/ManageCredit";
import ManageDiamond from "../components/Admin/ManageDiamond";
import ManageCoupons from "../components/Admin/ManageCoupons";
import AddCoupons from "../components/Admin/AddCoupons";
import Payout from "../components/Admin/Payout";

export default function Admin() {
  const params = useParams();
  return (
    <Layout>
      <div className="page-container">
        <div style={{ zIndex: 2 }} className="w-100">
          <Header />
          <Menu />
          <Container>
            {params.sub_url === "games" && <GameTable />}
            {params.sub_url === "credits" && <ManageCredits />}
            {params.sub_url === "coupons" && <ManageCoupons />}
            {params.sub_url === "add_coupons" && <AddCoupons />}
            {params.sub_url === "diamonds" && <ManageDiamond />}
            {params.sub_url === "payout" && <Payout />}
          </Container>
        </div>
      </div>
    </Layout>
  );
}
