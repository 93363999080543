import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import toast from "react-hot-toast";
import { useNavigate, useLocation } from "react-router-dom";

import { COUPON_TYPES, COUPON_VALUES } from "../../configs/coupons";
import { createCouponApi, editCouponApi } from "../../apis/coupon";
import {
  customToastSuccessStyle,
  customToastStyle,
} from "../../configs/constants";

export default function AddCoupons() {
  const nav = useNavigate();
  const { state } = useLocation();

  const [info, setInfo] = useState({
    Sport: state?.Sport || "",
    "Coupon Type": state?.["Coupon Type"] || "",
    Value: state?.Value || "",
    id: state?.id || "",
    Multiplier: state?.["Multiplier"] || "",
  });

  const saveHandler = async () => {
    if (!info?.Sport) {
      return toast("Please select sport", customToastStyle);
    }
    if (!info["Coupon Type"]) {
      return toast("Please select coupon type", customToastStyle);
    }
    if (!info.Value) {
      return toast("Please select value", customToastStyle);
    }
    if (!info.Multiplier) {
      return toast("Please input multiplier", customToastStyle);
    }

    let coupon = { ...info };
    if (coupon?.id) {
      const res = await editCouponApi(coupon);
      if (res === true) {
        toast("Edit coupon successfully", customToastSuccessStyle);
        return nav("/admin/coupons");
      }
      toast("Error to edit coupon", customToastStyle);
    } else {
      delete coupon.id;
      // @create
      const res = await createCouponApi(coupon);
      if (res === true) {
        toast("Coupon created successfully", customToastSuccessStyle);
        return nav("/admin/coupons");
      }
      toast("Error to create coupon", customToastStyle);
    }
  };

  return (
    <Form>
      <h2>Add Coupons</h2>
      <Form.Group controlId="formFile" className="mt-3">
        <Form.Label>Sports</Form.Label>
        <Form.Select
          aria-label="Sports"
          onChange={(e) => {
            setInfo({ ...info, ...{ Sport: e.target.value } });
          }}
          defaultValue={info?.Sport}
          disabled={!!state}
          style={{ opacity: state ? 0.8 : 1 }}
        >
          <option>Open this select option</option>
          <option value="Football">Football</option>
          <option value="NBA Basketball">NBA Basketball</option>
          <option value="Other Basketball">Other Basketball</option>
          <option value="Cricket">Cricket</option>
        </Form.Select>
      </Form.Group>
      <Form.Group className="mt-3">
        <Form.Label>Coupon Type</Form.Label>
        <Form.Control
          type="text"
          defaultValue={info["Coupon Type"]}
          onChange={(e) =>
            setInfo({ ...info, ...{ "Coupon Type": e.target.value } })
          }
        />
      </Form.Group>
      <Form.Group className="mt-3">
        <Form.Label>Value</Form.Label>
        <Form.Control
          type="text"
          defaultValue={info["Value"]}
          onChange={(e) => setInfo({ ...info, ...{ Value: e.target.value } })}
        />
      </Form.Group>
      <Form.Group className="mt-3">
        <Form.Label>Multiplier</Form.Label>
        <Form.Control
          type="number"
          defaultValue={info["Multiplier"]}
          onChange={(e) =>
            setInfo({ ...info, ...{ Multiplier: e.target.value } })
          }
        />
      </Form.Group>
      <Form.Group controlId="formFile" className="mt-3">
        <Button variant="primary" onClick={saveHandler}>
          Save Coupons
        </Button>
      </Form.Group>
    </Form>
  );
}
