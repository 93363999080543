import React, { useState } from "react";
import { Modal, Button, Form, Image } from "react-bootstrap";
import toast from "react-hot-toast";

import CoinImg from "../../assets/img/coin.png";
import { customToastStyle } from "../../configs/constants";
import { store } from "../../store";

export default function SetBetAmount({
  setBetAmount,
}: {
  setBetAmount: Function;
}) {
  const [userInfo] = store.useState("userInfo");

  const creditBalance = (userInfo as any)?.credit;

  const [amount, setAmount] = useState("");
  const [show, setShow] = useState(true);

  const nextHandler = () => {
    if (!amount) {
      return toast("Please input amount", customToastStyle);
    }

    if (parseInt(amount) < 1) {
      return toast("Amount should be greater than 1", customToastStyle)
    }

    if (parseFloat(amount) > parseFloat(creditBalance)) {
      return toast("Credit balance error", customToastStyle);
    }

    setShow(false);
    setBetAmount(amount);
  };

  return (
    <Modal show={show} centered>
      <Modal.Header>
        <Modal.Title style={{ fontSize: 18, fontWeight: "bold" }}>
          Input bet amount
          <Image src={CoinImg} width={40} />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="formFile" className="mb-3">
            <Form.Control
              type="text"
              defaultValue={amount}
              onChange={(e: any) => {
                setAmount(e.target.value);
              }}
              placeholder="Please input bet amount"
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={nextHandler}>
          Next
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
