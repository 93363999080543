import React, { useEffect, useMemo, useState } from "react";
import { Button, Form, Stack } from "react-bootstrap";
import DataTable from "react-data-table-component";
import Web3 from "web3";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

import { getCouponsApi, getUserApi } from "../../apis/coupon";
import { capitalizeFirstLetter } from "../../utils/time";
import { getCouponPriceApi, buyCouponApi } from "../../apis/coupon";
import {
  WRITE_COUPON_CONTRACT,
  WRITE_USDT_CONTRACT,
} from "../../configs/smart_contracts";
import { COUPON_ADDRESS } from "../../configs/addresses";
import { store } from "../../store";
import {
  customToastStyle,
  customToastSuccessStyle,
} from "../../configs/constants";

export default function ManageCoupons() {
  const nav = useNavigate();

  const [coupons, setCoupons] = useState([]);
  const [sports, setSports] = useState("");
  const [couponType, setCouponType] = useState("");
  const [dbCouponPrice, setDbCouponPrice] = useState("0");
  const [loading, setLoading] = useState(false);

  const [account] = store.useState("account");
  const [, setUserInfo] = store.useState("userInfo");

  useEffect(() => {
    (async () => {
      setDbCouponPrice(await getCouponPriceApi());
      const couponData = await getCouponsApi();
      // @ts-ignore
      setCoupons(couponData);
    })();
  }, []);

  const tableData = useMemo(() => {
    if (sports === "") return coupons;

    const filteredSport = coupons?.filter(
      (item: any) => item?.Sport === sports
    );

    return filteredSport;
  }, [coupons, sports]);

  const filteredTableData = useMemo(() => {
    if (couponType === "") return tableData;

    const filteredCouponType = tableData?.filter(
      (item: any) => item["Coupon Type"].search(couponType) !== -1
    );

    return filteredCouponType;
  }, [tableData, couponType]);

  const buyCouponHandler = async (id: string) => {
    // TODO: Implement buying coupon logic
    console.log("Buying coupon:", id);

    if (loading) return;
    if (dbCouponPrice === "0")
      return toast("Coupon price error", customToastStyle);

    const writeCouponContract = await WRITE_COUPON_CONTRACT();
    const writeUsdtContract = await WRITE_USDT_CONTRACT();

    try {
      setLoading(true);
      await writeUsdtContract.methods
        .approve(COUPON_ADDRESS, Web3.utils.toWei(dbCouponPrice, "ether"))
        .send({ from: account });

      await writeCouponContract.methods.mintCoupons(1).send({ from: account });

      const res = await buyCouponApi({ wallet: account, couponId: id });

      const _userInfo = await getUserApi(account);
      setUserInfo(_userInfo);

      if (res) {
        toast("Coupon has been bought", customToastSuccessStyle);
        nav("/my-coupons");
      } else {
        toast("Error to buy coupon", customToastStyle);
      }
    } catch (e: any) {
      toast(e?.message, customToastStyle);
    } finally {
      setLoading(false);
    }
  };

  const columns = useMemo(() => {
    const data = [];
    if (Array.isArray(tableData)) {
      for (const key in tableData[0]) {
        if (key === "id") continue;
        if (key === "Multiplier") continue;
        data.push({
          name: capitalizeFirstLetter(key),
          selector: (row: any) => row[key],
          sortable: true,
        });
      }
      //extra key
      if (tableData[0]) {
        if (!("Multiplier" in tableData[0])) {
          data.push({
            name: "Multiplier",
            selector: (row: any) => row["Multiplier"] || 0,
            sortable: true,
          });
        }
        data.push({
          name: "Get",
          selector: (row: any) => (
            <Button size="sm" onClick={() => buyCouponHandler(row?.id)}>
              Get
            </Button>
          ),
          sortable: false,
        });
      }
    }
    return data;
  }, [tableData]);

  return (
    <Form>
      <h2>Get Coupons</h2>
      <Stack
        direction="horizontal"
        className="w-100 justify-content-md-between mt-5"
      >
        <Stack direction="horizontal">
          <Form.Select
            aria-label="Sports"
            onChange={(e) => {
              setSports(e.target.value);
            }}
            style={{ maxWidth: 250 }}
            defaultValue={sports}
          >
            <option value="">All</option>
            <option value="Football">Football</option>
            <option value="NBA Basketball">NBA Basketball</option>
            <option value="Other Basketball">Other Basketball</option>
            <option value="Cricket">Cricket</option>
          </Form.Select>

          <Form.Control
            type="text"
            aria-label="Sports"
            placeholder="Coupon Type"
            onChange={(e: any) => {
              setCouponType(e.target.value);
            }}
            style={{ maxWidth: 250, marginLeft: 20 }}
            defaultValue={couponType}
          ></Form.Control>
        </Stack>
        <h4>1 Coupon = {dbCouponPrice} USDT</h4>
      </Stack>
      <Stack className="mt-4">
        <DataTable columns={columns} data={filteredTableData} pagination />
      </Stack>
    </Form>
  );
}
