import { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Table from "react-bootstrap/Table";
import { useNavigate } from "react-router-dom";

export default function WinningCoupons({
  show,
  winningCoupons,
}: {
  show: boolean;
  winningCoupons: any;
}) {
  const nav = useNavigate();

  return (
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show}
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          Winning Coupons
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h5>
          You got {winningCoupons.length}{" "}
          {winningCoupons.length > 1 ? "coupons" : "coupon"}
        </h5>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>No</th>
              <th>Sport</th>
              <th>Coupon Type</th>
              <th>Value</th>
            </tr>
          </thead>
          <tbody>
            {winningCoupons.map((item: any, index: number) => {
              return (
                <tr>
                  <td>{index + 1}</td>
                  <td>{item["Sport"]}</td>
                  <td>{item["Coupon Type"]}</td>
                  <td>{item["Value"]}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => {
            nav("/my-coupons");
          }}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
