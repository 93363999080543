import React, { useState, useMemo, useEffect } from "react";
import {
  Container,
  Form,
  Stack,
  Button,
  Offcanvas,
  Table,
} from "react-bootstrap";
import DataTable from "react-data-table-component";

import { getGamesApi } from "../apis/coupon";

import { useNavigate } from "react-router-dom";

export default function GameTable() {
  const nav = useNavigate();

  const [tableData, setTableData] = useState([]);
  const [sports, setSports] = useState("");
  const [coming, setComing] = useState("");
  const [showCoupon, setShowCoupon] = useState(false);
  const [winningCoupons, setWinningCoupons]: [
    selectedCoupons: any,
    setSelectedCoupons: Function
  ] = useState([]);

  const viewTableData = useMemo(() => {
    if (sports === "") return tableData;
    return tableData?.filter((item: any) => item?.sports === sports);
  }, [tableData, sports]);

  const filteredTableData = useMemo(() => {
    switch (coming) {
      case "Upcoming":
        return viewTableData?.filter(
          (item: any) =>
            new Date(item.startDate + " " + item.startTime) >= new Date()
        );
      case "Finished":
        return viewTableData?.filter(
          (item: any) =>
            new Date(item.startDate + " " + item.startTime) < new Date()
        );
      default:
        return viewTableData;
    }
  }, [viewTableData, coming]);

  const columns = [
    {
      name: "Sports",
      selector: (row: any) => row.sports,
      sortable: true,
    },
    {
      name: "Home Team",
      selector: (row: any) => row.home,
      sortable: true,
    },
    {
      name: "Away Team",
      selector: (row: any) => row.away,
      sortable: true,
    },
    {
      name: "Start Date",
      selector: (row: any) => row.startDate + " " + row.startTime,
      sortable: true,
    },
    {
      name: "Game Status",
      selector: (row: any) =>
        new Date(row.startDate + " " + row.startTime) >= new Date()
          ? "Upcoming"
          : "Finished",
      sortable: true,
    },
    {
      name: "Winning Coupons",
      selector: (row: any) => {
        let coupons: any = [];
        try {
          coupons = JSON.parse(row.coupons);
        } catch (e) {}
        if (coupons.length > 0) {
          return (
            <Button
              variant="outline-secondary"
              size="sm"
              onClick={() => {
                setShowCoupon(true);
                setWinningCoupons(coupons);
              }}
            >
              Win Coupons
            </Button>
          );
        }
        return null;
      },
      sortable: true,
    },
    {
      name: "Game Type",
      selector: (row: any) => row.gameType,
      sortable: true,
    },
    {
      name: "Bet",
      selector: (row: any) =>
        new Date(row.startDate + " " + row.startTime) >= new Date() ? (
          <Button
            size="sm"
            variant="success"
            onClick={() => {
              nav("/bet/diamond", { state: row });
            }}
          >
            Bet
          </Button>
        ) : null,
      sortable: true,
    },
  ];

  useEffect(() => {
    (async () => {
      setTableData(await getGamesApi());
    })();
  }, []);

  return (
    <Container>
      <Offcanvas show={showCoupon} onHide={() => setShowCoupon(false)}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Winning Coupons</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>#</th>
                <th>Coupon Type</th>
                <th>Coupon Value</th>
                <th>Payout</th>
              </tr>
            </thead>
            <tbody>
              {winningCoupons.map((coupon: any, index: number) => {
                return (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{coupon.couponType}</td>
                    <td>{coupon.couponValue}</td>
                    <td>{coupon.payout}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Offcanvas.Body>
      </Offcanvas>

      <Stack
        direction="horizontal"
        className="w-100 justify-content-md-between mt-5"
      >
        <h1>Games List</h1>
        <Stack direction="horizontal">
          <Form.Select
            aria-label="Sports"
            onChange={(e) => {
              setSports(e.target.value);
            }}
            style={{ maxWidth: 150 }}
            defaultValue={sports}
          >
            <option value="">All</option>
            <option value="Football">Football</option>
            <option value="NBA Basketball">NBA Basketball</option>
            <option value="Other Basketball">Other Basketball</option>
            <option value="Cricket">Cricket</option>
          </Form.Select>
          <Form.Select
            aria-label="Sports"
            onChange={(e) => {
              setComing(e.target.value);
            }}
            style={{ maxWidth: 150, marginLeft: 10 }}
            defaultValue={coming}
          >
            <option value="">All</option>
            <option value="Upcoming">Upcoming</option>
            <option value="Finished">Finished</option>
          </Form.Select>
        </Stack>
      </Stack>
      <Stack className="mt-4">
        <DataTable columns={columns} data={filteredTableData} pagination />
      </Stack>
    </Container>
  );
}
