import React, { useState, useEffect } from "react";
import { Container, Button, Image, Form, Stack } from "react-bootstrap";
import Countdown from "react-countdown";
// @ts-ignore
import { confirm } from "react-bootstrap-confirmation";
import { AiOutlineClear } from "react-icons/ai";
// @ts-ignore
import { v4 as uuidv4 } from "uuid";

// @ts-ignore
import { Chance } from "chance";

import SetBetAmount from "./SetBetAmount";
import CoinImg from "../../assets/img/coin.png";

import { getKenoTimeLimitTowerMasterApi, setBuyCreditApi } from "../../apis/coupon";

import { store } from "../../store";
import toast from "react-hot-toast";
import { customToastStyle } from "../../configs/constants";

const chance = new Chance();

const scoreTable = {
  1: {
    1: 2,
  },
  2: {
    1: 1,
    2: 7,
  },
  3: {
    2: 3,
    3: 25,
  },
  4: {
    2: 1,
    3: 8,
    4: 80,
  },
  5: {
    3: 3,
    4: 15,
    5: 500,
  },
  6: {
    3: 1,
    4: 7,
    5: 50,
    6: 1500,
  },
  7: {
    3: 1,
    4: 3,
    5: 20,
    6: 100,
    7: 5000,
  },
  8: {
    4: 2,
    5: 10,
    6: 50,
    7: 1000,
    8: 15000,
  },
}

export default function KenoBoard() {
  const [account] = store.useState("account");
  const [userInfo, setUserInfo] = store.useState("userInfo");

  const [gamePeriod, setGamePeriod] = useState(5 * 60 * 1000);
  const [timeLoaded, setTimeLoaded] = useState(false);

  const [endDate, setEndDate] = useState(Date.now() + gamePeriod);
  const [pickEnable, setPickEnable] = useState(true);

  const [kb, setKb] = useState([]);
  const [picked, setPicked] = useState([]);
  const [pickLimit] = useState(8);
  const [winningLimit] = useState(20);
  const [maxPricePerGame] = useState(20);
  const [qpNum, setQPNum] = useState();
  const [winningDraw, setWinningDraw] = useState([]);
  //[{picked, winning, scoreCount}]
  const [winningDrawList, setWinningDrawList] = useState([]);
  const [betAmount, setBetAmount] = useState("");
  const [currentRound, setCurrentRound] = useState(0);
  const [repeatNumber, setRepeatNumber] = useState(2);

  useEffect(() => {
    (async () => {
      const period = await getKenoTimeLimitTowerMasterApi() || "4";
      setGamePeriod(parseFloat(period) * 60 * 1000);
      // setGamePeriod(10 * 1000);
      setEndDate((Date.now() + parseFloat(period) * 60 * 1000))
      // setEndDate((Date.now() + 10 * 1000))
      setTimeLoaded(true);
    })()
  }, [])

  const [boardOptions, setBoardOptions] = useState({
    totalAmount: 0,
    pricePer: 0,
    numGames: 0,
  });

  const clearHandler = async () => {
    if (pickEnable) {
      const res = await confirm(
        `Are you sure to clear picked numbers?`
      );
      if (res) {
        setPicked([])
      }
    }
  }

  const renderer: any = ({
    minutes,
    seconds,
    completed,
  }: {
    minutes: number;
    seconds: number;
    completed: boolean;
  }) => {
    if (minutes === 0 && seconds <= 3) {
      setPickEnable(false);
    }

    if (completed) {
      // Render a completed state
    }
    return (
      <div style={{ color: "#27ff00", display: "flex" }}>
        <div
          style={{
            width: 50,
            height: 50,
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            borderRadius: 10,
            borderStyle: "solid",
            borderWidth: 1,
            borderColor: "gray",
          }}
        >
          {minutes}
        </div>
        <div
          style={{
            width: 20,
            height: 50,
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}
        >
          :
        </div>
        <div
          style={{
            width: 50,
            height: 50,
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            borderRadius: 10,
            borderStyle: "solid",
            borderWidth: 1,
            borderColor: "gray",
          }}
        >
          {seconds}
        </div>
      </div>
    );
  };

  useEffect(() => {
    let newKb: any = [];
    let add: any = 1;
    // init rows of Keno board
    for (let r = 0; r < 8; r++) {
      newKb.push([]);
    }

    // populate Keno board
    for (let r = 0; r < newKb.length; r++) {
      for (let x = 1; x <= 10; x++) {
        newKb[r].push(add as never);
        add++;
      }
    }

    setKb(newKb);
  }, []);

  /**
   * Finish the game.
   * - disable the board
   * - generate winning numbers
   * - show winnint numbers
   */
  const finGetWin = () => {
    let firstPicked = []
    if (picked.length === 0) {
      firstPicked = quikPik({});
    }

    if (currentRound + 1 < repeatNumber) {
      setCurrentRound((prev) => {
        return prev + 1
      })
    }
    showWinningDraw(firstPicked);
  };

  /**
   * Generate NPC games.
   */
  const genNPC = () => {
    // generate dummy data for other players
    let players = [];
    const n = chance.integer({ min: 10, max: 20 });
    for (let i = 0; i < n; i++) {
      players.push([]);
    }
    for (let y = 0; y < players.length; y++) {
      players[y].push(
        // @ts-ignore
        chance.unique(chance.natural, pickLimit, { min: 1, max: 80 })
      );
    }
    return players;
  };

  // eslint-disable-next-line.
  const calcWinnings = () => {
    const npcs = genNPC(); // eslint-disable-line
    // calc flat winnings for each npc

    // calc flat winnings for client

    // calc actual winnings
  };

  /**
   * GUI Utility.
   * Generate and display the winning Keno spots.
   */
  const showWinningDraw = (firstPicked: any) => {
    // generate a winning draw
    const d = chance.unique(chance.natural, winningLimit, { min: 1, max: 80 });
    // set the winning spots

    setWinningDrawList((prev: any) => {
      const _picked = picked.length > 0 ? picked : firstPicked;
      const matches = _picked.filter((n: Number) => d.indexOf(n) > 0);
      const matchCount = matches.length;

      let scoreCount = 0;
      if (matchCount !== 0) {
        // @ts-ignore
        scoreCount = scoreTable[_picked.length][matchCount] || 0;
      }

      // calculate score and reduce balance

      if (betAmount) {
        let totalReward = scoreCount * parseFloat(betAmount) - parseFloat(betAmount);
        const credit = parseFloat((userInfo as any)?.credit) || 0;
        let newBalance = credit + totalReward;

        if (newBalance < 0) {
          newBalance = 0
        }

        if (totalReward > credit) {
          totalReward = credit; // balance should be 0
        }

        setUserInfo({
          ...(userInfo as any),
          credit: newBalance,
        });

        setBuyCreditApi(account, totalReward);

      }


      prev.push({ picked: _picked, winning: d, scoreCount })
      return prev
    })
    setWinningDraw(d);

    setTimeout(() => {
      setWinningDraw([])
    }, 5000);
  };

  /**
   * GUI Form Utility.
   * QuikPik: auto select a draw of numbers of length `n`.
   * @param {event} e - Click event.
   */
  const quikPik = (e: any) => {
    if (e.preventDefault) {
      e.preventDefault();
    }
    // determine to use default pickLimit or user defined qpNum.
    let num = qpNum ? qpNum : pickLimit;
    const d = chance.unique(chance.natural, num, { min: 1, max: 80 });
    setPicked(d);
    return d;
  };

  /**
   * GUI Form Utility.
   * Process changes to QuikPik form.
   * @param {event} e - Click event.
   */
  const handleChange = (e: any) => {
    e.preventDefault();
    // @ts-ignore
    if (e.target.value > pickLimit) setQPNum(pickLimit);
    else setQPNum(e.target.value);
  };

  /**
   * GUI Form Utility.
   * Process clicked elements within the Keno game board.
   * @param {event} e - Click event
   * @param {number} val - The cell that was clicked within the Keno game board
   */
  const addPicked = (e: any, val: any) => {
    e.preventDefault();
    if (!pickEnable) {
      return;
    }
    // guard check limit met
    if (picked.length < pickLimit) {
      let nPicked = picked.slice(0);

      nPicked.push(val as never);
      setPicked(nPicked);

      // disable the clicked item
    }
  };

  const removePicked = (e: any, val: any) => {
    e.preventDefault();
    if (!pickEnable) {
      return;
    }
    // guard check limit met
    setPicked((prev: never[]) => {
      const _prev: never[] = [...prev];
      return _prev.filter((item) => item !== val);
    });
    // if (picked.length < pickLimit) {

    //   // disable the clicked item
    // }
  };

  /**
   * GUI Form Utility.
   * Process changes to price per game input field.
   * @param {event} e - Click event
   */
  const handlePricePerGame = (e: any) => {
    e.preventDefault();
    let val = e.target.value
      .trim()
      .replace(/[A-Za-z]/g, "")
      .replace(/,/g, "");

    if (val > maxPricePerGame) val = maxPricePerGame;

    setBoardOptions({
      ...boardOptions,
      pricePer: val,
      totalAmount: formatCurrency(boardOptions.numGames * val),
    });
  };

  /**
   * GUI Utility.
   * Process changes to number of games input field.
   * @param {event} e - Click event
   */
  const handleNumGamesChange = (e: any) => {
    e.preventDefault();
    let val = e.target.value;

    setBoardOptions({
      ...boardOptions,
      numGames: val,
      totalAmount: boardOptions.pricePer * val,
    });
  };

  /**
   * GUI Utility.
   * Format a string input to a NA currency format (0.00).
   * @param {string|number} num - String input
   */
  const formatCurrency = (num: any) => {
    if (!num) num = "";

    if (typeof num === "number") {
      num = num.toString();
    }

    let value = num.trim().replace(/,/g, "");

    while (value.substring(0, 2) === "00") {
      value = value.substring(1);
    }

    if (value[0] === ".") {
      value = "0" + value;
    } else if (value.length) {
      let n = Number(value);

      if (isNaN(n)) {
        value = parseFloat(value);

        if (isNaN(value)) {
          return "";
        }
      }

      let parts = (value + "").split(".");
      value = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

      if (parts.length > 1) {
        value += "." + parts[1];
      }
    }

    return value;
  };

  /**
   * Render Helper.
   * Display a number `n`.
   * @param {number} n - The number (from and array iteration) to display
   */
  const renderNum = (n: any) => {
    return (
      <span className="numbers__your-num" key={n}>
        {n + " "}
      </span>
    );
  };
  const renderPicked = (n: any) => {
    return (
      <div style={{ fontWeight: "bold", color: "#27ff00", minWidth: 30, height: 30, borderRadius: 30, display: "flex", justifyContent: "center", alignItems: "center", background: "#fff" }} key={n}>
        {/* 🗙 */}
        {n}
      </div>
    );
  };

  /**
   * Render Helper.
   * Builds the HTML/JSX required to render the winning numbers.
   */
  const renderWinningDraw = () => {
    return winningDraw.length > 0 ? (
      <div className="numbers__your">
        <h3>Winning Numbers</h3>
        {winningDraw.map((n) => renderNum(n))}
      </div>
    ) : null;
  };

  /**
   * Render helper.
   * Builds the HTML/JSX required to render the Keno board.
   * Additional check made here to "x" the cell for when QuikPik is used.
   */
  const renderBoard = () => {
    return (
      <div className={`board `}>
        <div className="count-container">
          {
            ((account && parseFloat(betAmount) > 0) || !account) && timeLoaded && (<Countdown date={endDate} key={endDate} renderer={renderer}
              onComplete={() => {
                if (currentRound + 1 < repeatNumber) {
                  if (betAmount) {
                    const credit = parseFloat((userInfo as any)?.credit) || 0;
                    if (parseFloat(betAmount) > credit) { // balance error => finish game
                      toast("Please buy credit to play game", customToastStyle)
                    } else {
                      setTimeout(() => {
                        setPickEnable(true)
                        setEndDate(Date.now() + gamePeriod)
                      }, 5000);
                    }
                  } else {
                    setTimeout(() => {
                      setPickEnable(true)
                      setEndDate(Date.now() + gamePeriod)
                    }, 5000);
                  }
                }
                return finGetWin();
              }} /> as any)
          }
        </div>

        {
          // @ts-ignore
          // scoreTable[picked.length] &&
          <div className="reward-container">
            <div className="reward-match">
              <span>Match</span>
              {
                // @ts-ignore
                scoreTable[picked.length] && Object.keys(scoreTable[picked.length]).map((item) => {
                  return (
                    <div key={uuidv4()}>
                      {item}
                    </div>
                  )
                })
              }
            </div>

            <div className="horizontal-devider" />

            <div className="reward-match">
              <span>Pays</span>
              {
                // @ts-ignore
                scoreTable[picked.length] && Object.values(scoreTable[picked.length]).map((item: any) => {
                  return (
                    <div key={uuidv4()}>
                      x{item}
                    </div>
                  )
                })
              }
            </div>
          </div>
        }


        <div className="board__card">
          {kb.map((row: any) => (
            <div className="row" key={uuidv4()}>
              {row.map((col: any) => {
                return (
                  <div
                    style={{ position: "relative" }}
                    className={
                      `col ${winningDraw.indexOf(col as never) !== -1 ? "win-draw-number" : ""}`
                    }
                    key={uuidv4()}
                    onClick={(e) => {
                      if (picked.indexOf(col as never) === -1) {
                        addPicked(e, col);
                      } else {
                        removePicked(e, col);
                      }
                    }}
                  >
                    {picked.indexOf(col as never) === -1
                      ? renderNum(col)
                      : renderPicked(col)}
                  </div>
                );
              })}
            </div>
          ))}
        </div>
      </div>
    );
  };

  /**
   * Render helper.
   * Conditionally builds the HTML/JSX required to render the QuikPik form.
   * If the end user has not selected any Keno spots manually, this form will display.
   *
   * Allows user to pick a number less than or equal to the `pickLimit` which will be
   * the number of Keno spots to generate using our selected RNG
   */
  const showQuikPik = () => {
    return (
      <>
        {picked.length === 0 ? (
          <form className="quik-pik" onSubmit={quikPik}>
            <label htmlFor="quik-pik-limit">How many numbers?</label>
            <input
              type="number"
              id="quik-pik-limit"
              name="quik-pik-limit"
              min={1}
              max={pickLimit}
              onChange={handleChange}
              onBlur={handleChange}
              //   placeholder={pickLimit}
              value={qpNum}
            />
            <input type="submit" value="Quik Pik" />
          </form>
        ) : null}
      </>
    );
  };

  /**
   * Render helper.
   * Builds the HTML/JSX required to render the end users Keno spots that match the winning Keno spots.
   */
  const renderMatchingWins = () => {
    const matches = picked.filter((n) => winningDraw.indexOf(n) > 0);
    const matchCount = matches.length;
    return (
      <>
        {winningDraw.length > 0 ? (
          <div className="numbers__matching">
            <h3>Matches: {matchCount}</h3>
            {matches.map((n) => renderNum(n))}
          </div>
        ) : null}
      </>
    );
  };

  /**
   * Render helper.
   * Builds the HTML/JSX required to render the statistics section of the application.
   * The following are built:
   * - end users Keno spots
   * - the winning Keno spots
   * - the Keno spots the end user guessed that exist within the winning Keno spots
   */
  const renderNumbers = () => {
    return (
      <div className="numbers">
        {renderWinningDraw()}

        {renderMatchingWins()}
      </div>
    );
  };

  const renderHistory = () => {
    return <div className="history-container">
      <div className="total-bet-counter">Total bets count {winningDrawList.length}</div>
      {
        winningDrawList.map((item: any, index: number) => {
          return (
            <div key={uuidv4()} className="history-item">
              <div style={{ fontWeight: "400", color: "#f1f1f1" }}>Ticket Number: {index + 1}</div>
              {
                betAmount && <div style={{ display: "flex", width: "100%", justifyContent: "space-between", fontWeight: "bold", color: "white" }}>Win: {(item.scoreCount * parseFloat(betAmount)) || 0}</div>
              }
              <div>Winning Numbers</div>
              {item.winning.map((n: number) => <span className="winning-number" key={uuidv4()}>
                {n + " "}
              </span>)}
              <div>Your bet numbers</div>
              {item.picked.map((n: number) => <span className={`picked-number ${item.winning.indexOf(n) !== -1 && "picked-win-number"}`} key={uuidv4()}>
                {n + " "}
              </span>)}
            </div>
          )
        })
      }
    </div>;
  };

  return (
    <div>
      {
        account &&
        ((
          <SetBetAmount
            setBetAmount={(amount: string) => {
              setBetAmount(amount);
              setEndDate(Date.now() + gamePeriod);
            }}
          />
        ) as any)
      }

      <div style={{ display: "flex", justifyContent: "center" }}>
        Pick up to 1 ~ {pickLimit} Keno spots by clicking on the number in the
        Keno card.
      </div>

      <div className="keno">

        <Container
          style={{
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
            borderStyle: "solid",
            borderColor: "#888888",
            borderWidth: 1,
            borderRadius: 10,
            paddingTop: 8,
            paddingBottom: 8,
            marginTop: 30
          }}
        >
          <Stack
            direction="horizontal"
            style={{
              alignItems: "flex-end",
            }}
          >
            <Form.Label style={{ width: 140 }}>Repeat Number</Form.Label>
            <Form.Select
              aria-label="Repeat number"
              onChange={(e: any) => {
                setRepeatNumber(parseInt(e.target.value))
              }}
              style={{ maxWidth: 80 }}
              defaultValue={""}
            >
              <option value="2" disabled={currentRound + 1 > 2}>2</option>
              <option value="3" disabled={currentRound + 1 > 3}>3</option>
              <option value="4" disabled={currentRound + 1 > 4}>4</option>
              <option value="5" disabled={currentRound + 1 > 5}>5</option>
              <option value="10" disabled={currentRound + 1 > 10}>10</option>
              <option value="20" disabled={currentRound + 1 > 20}>20</option>
              <option value="50" disabled={currentRound + 1 > 50}>50</option>
            </Form.Select>

            {
              parseInt(betAmount) > 0 &&
              <div className="bet-amount">You bet {betAmount} credit <Image src={CoinImg} width={30} /></div>
            }

            <div className="current-round">Round: {currentRound + 1}</div>
            <div className="remain-round">Remain Round: {repeatNumber - currentRound - 1}</div>

            <Button style={{ width: 100, marginLeft: 50 }} onClick={clearHandler}>
              Clear <AiOutlineClear />
            </Button>
          </Stack>
        </Container>
        <Stack
          direction="horizontal"
          style={{
            justifyContent: "flex-start",
            alignItems: "flex-start",
            marginTop: 10,
          }}
        >
          {renderHistory()}
          {renderBoard()}
        </Stack>
      </div>
    </div>
  );
}
