import React, { useEffect, useMemo, useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import toast from "react-hot-toast";

import { customToastStyle } from "../../configs/constants";

export default function CreateGame({
  show,
  data,
  onHide,
  saveGame,
}: {
  show: boolean;
  data: any;
  onHide: () => void;
  saveGame: Function;
}) {
  const [info, setInfo] = useState({
    sports: data?.sports || "",
    home: data?.home || "",
    away: data?.away || "",
    startDate: data?.startDate || "",
    startTime: data?.startTime || "",
    gameType: data?.gameType || "",
    minDiamond: data?.minDiamond || "",
    maxDiamond: data?.maxDiamond || "",
  });

  useEffect(() => {
    setInfo({
      sports: data?.sports || "",
      home: data?.home || "",
      away: data?.away || "",
      startDate: data?.startDate || "",
      startTime: data?.startTime || "",
      gameType: data?.gameType || "",
      minDiamond: data?.minDiamond || "",
      maxDiamond: data?.maxDiamond || "",
    });
  }, [data]);

  const saveHandler = () => {
    if (!info.sports) {
      return toast("Please input sport", customToastStyle);
    }
    if (!info.home) {
      return toast("Please input home team", customToastStyle);
    }
    if (!info.away) {
      return toast("Please input away team", customToastStyle);
    }
    if (!info.startDate) {
      return toast("Please input start date", customToastStyle);
    }
    if (!info.startTime) {
      return toast("Please input start time", customToastStyle);
    }
    if (!info.gameType) {
      return toast("Please input gameType", customToastStyle);
    }
    saveGame(info);
    setInfo({
      sports: "",
      home: "",
      away: "",
      startDate: "",
      startTime: "",
      gameType: "",
      minDiamond: "",
      maxDiamond: "",
    });
    onHide();
  };

  return (
    <Modal show={show} onHide={onHide} scrollable>
      <Modal.Header closeButton>
        <Modal.Title>{data?.sports ? "Edit Game" : "Create Game"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="formFile" className="mb-3">
            <Form.Label>Sports</Form.Label>
            <Form.Select
              aria-label="Sports"
              onChange={(e) => {
                setInfo({ ...info, ...{ sports: e.target.value } });
              }}
              defaultValue={data?.sports}
            >
              <option>Open this select option</option>
              <option value="Football">Football</option>
              <option value="NBA Basketball">NBA Basketball</option>
              <option value="Other Basketball">Other Basketball</option>
              <option value="Cricket">Cricket</option>
            </Form.Select>
          </Form.Group>
          <Form.Group controlId="formFile" className="mb-3">
            <Form.Label>Game Type</Form.Label>
            <Form.Select
              aria-label="Game Type"
              defaultValue={data?.gameType}
              onChange={(e) => {
                setInfo({ ...info, ...{ gameType: e.target.value } });
              }}
            >
              <option>Open this select option</option>
              <option value="Diamond">Diamond</option>
              <option value="Coupon">Coupon</option>
              <option value="Diamond/Coupon">Diamond/Coupon</option>
            </Form.Select>
          </Form.Group>
          <Form.Group controlId="formFile" className="mb-3">
            <Form.Label>Home Team</Form.Label>
            <Form.Control
              type="text"
              defaultValue={data?.home}
              onChange={(e) =>
                setInfo({ ...info, ...{ home: e.target.value } })
              }
            />
          </Form.Group>
          <Form.Group controlId="formFile" className="mb-3">
            <Form.Label>Away Team</Form.Label>
            <Form.Control
              type="text"
              defaultValue={data?.away}
              onChange={(e) =>
                setInfo({ ...info, ...{ away: e.target.value } })
              }
            />
          </Form.Group>
          <Form.Group controlId="formFile" className="mb-3">
            <Form.Label>Start Date</Form.Label>
            <Form.Control
              type="date"
              defaultValue={info?.startDate}
              onChange={(e) =>
                setInfo({ ...info, ...{ startDate: e.target.value } })
              }
            />
          </Form.Group>
          <Form.Group controlId="formFile" className="mb-3">
            <Form.Label>Start Time</Form.Label>
            <Form.Control
              type="time"
              defaultValue={info?.startTime}
              onChange={(e) =>
                setInfo({ ...info, ...{ startTime: e.target.value } })
              }
            />
          </Form.Group>
          {(info?.gameType === "Diamond" ||
            info?.gameType === "Diamond/Coupon") && (
            <Form.Group controlId="formFile" className="mb-3">
              <Form.Label>Min Diamond</Form.Label>
              <Form.Control
                type="number"
                defaultValue={info?.minDiamond}
                onChange={(e) =>
                  setInfo({ ...info, ...{ minDiamond: e.target.value } })
                }
              />
            </Form.Group>
          )}
          {(info?.gameType === "Diamond" ||
            info?.gameType === "Diamond/Coupon") && (
            <Form.Group controlId="formFile" className="mb-3">
              <Form.Label>Max Diamond</Form.Label>
              <Form.Control
                type="number"
                defaultValue={info?.maxDiamond}
                onChange={(e) =>
                  setInfo({ ...info, ...{ maxDiamond: e.target.value } })
                }
              />
            </Form.Group>
          )}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={saveHandler}>Save</Button>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
