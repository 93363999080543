import React, { useEffect, useState } from "react";
import { Card, Container, Form, Image, Button, Stack } from "react-bootstrap";
import toast from "react-hot-toast";
import Spinner from "react-bootstrap/Spinner";

import {
  getCreditPriceApi,
  setCreditPriceApi,
  getCostTowerMasterApi,
  setCostTowerMasterApi,
  getTimeLimitTowerMasterApi,
  getKenoTimeLimitTowerMasterApi,
  setTimeLimitTowerMasterApi,
  setKenoTimeLimitTowerMasterApi,
  setCouponPriceApi,
  getCouponPriceApi,
} from "../../apis/coupon";
import CoinImg from "../../assets/img/coin.png";
import {
  customToastStyle,
  customToastSuccessStyle,
} from "../../configs/constants";
import {
  WRITE_CREDIT_CONTRACT,
  READ_CREDIT_CONTRACT,
  READ_COUPON_CONTRACT,
  WRITE_COUPON_CONTRACT,
} from "../../configs/smart_contracts";
import { store } from "../../store";

export default function ManageCredits() {
  const [amount, setAmount] = useState("");
  const [gamePrice, setGamePrice] = useState("");
  const [dbGamePrice, setDbGamePrice] = useState("");
  const [price, setPrice] = useState("0");
  const [dbTimeLimit, setDbTimeLimit] = useState("0");
  const [timeLimit, setTimeLimit] = useState("");
  const [dbKenoTimeLimit, setDbKenoTimeLimit] = useState("0");
  const [kenoTimeLimit, setKenoTimeLimit] = useState("");
  const [dbCouponPrice, setDbCouponPrice] = useState("0");
  const [couponPrice, setCouponPrice] = useState("");
  const [loading, setLoading] = useState(false);
  const [couponLoading, setCouponLoading] = useState(false);

  const [account] = store.useState("account");

  useEffect(() => {
    (async () => {
      setPrice(await getCreditPriceApi());
      setDbGamePrice(await getCostTowerMasterApi());
      setDbTimeLimit(await getTimeLimitTowerMasterApi());
      setDbKenoTimeLimit(await getKenoTimeLimitTowerMasterApi());
      setDbCouponPrice(await getCouponPriceApi());
    })();
  }, []);

  const saveCreditHandler = async () => {
    if (loading) return;
    const owner = await READ_CREDIT_CONTRACT.methods.owner().call();
    if (account !== owner) {
      return toast("Only the owner can set credit price", customToastStyle);
    }
    if (isNaN(parseFloat(amount))) {
      return toast("Please enter a valid amount", customToastStyle);
    }
    try {
      setLoading(true);
      const writeCreditContract = await WRITE_CREDIT_CONTRACT();
      await writeCreditContract.methods
        .setTokenPrice(amount, 1)
        .send({ from: account });
      const res = await setCreditPriceApi(amount);
      if (res) {
        toast("Credit price has been saved", customToastSuccessStyle);
        setPrice(amount);
        setAmount("");
      } else {
        toast("Error to save credit price", customToastStyle);
      }
    } catch (e) {
      toast("Error saving credit price", customToastStyle);
    } finally {
      setLoading(false);
    }
  };

  const saveGamePriceHandler = async () => {
    if (isNaN(parseFloat(gamePrice))) {
      return toast("Please enter a valid price", customToastStyle);
    }
    const res = await setCostTowerMasterApi(gamePrice);
    if (res) {
      toast("Set cost to play tower master", customToastSuccessStyle);
      setDbGamePrice(gamePrice);
      setGamePrice("");
    } else {
      toast("Error to save cost", customToastStyle);
    }
  };

  const saveTimeLimitHandler = async () => {
    if (isNaN(parseFloat(timeLimit))) {
      return toast("Please enter a valid time", customToastStyle);
    }
    const res = await setTimeLimitTowerMasterApi(timeLimit);
    if (res) {
      toast("Set time limit for tower master", customToastSuccessStyle);
      setDbTimeLimit(timeLimit);
      setTimeLimit("");
    } else {
      toast("Error to save time limit", customToastStyle);
    }
  };

  const saveKenoTimeLimitHandler = async () => {
    if (isNaN(parseFloat(kenoTimeLimit))) {
      return toast("Please enter a valid time", customToastStyle);
    }
    const res = await setKenoTimeLimitTowerMasterApi(kenoTimeLimit);
    if (res) {
      toast("Set time limit for KENO", customToastSuccessStyle);
      setDbKenoTimeLimit(kenoTimeLimit);
      setKenoTimeLimit("");
    } else {
      toast("Error to save time limit", customToastStyle);
    }
  };

  const saveCouponPriceHandler = async () => {
    if (isNaN(parseFloat(couponPrice))) {
      return toast("Please enter a valid price", customToastStyle);
    }
    if (couponLoading) return;
    const owner = await READ_COUPON_CONTRACT.methods.owner().call();
    if (account !== owner) {
      return toast("Only the owner can set coupon price", customToastStyle);
    }

    try {
      setCouponLoading(true);
      const writeCouponContract = await WRITE_COUPON_CONTRACT();
      await writeCouponContract.methods
        .setTokenPrice(couponPrice, 1)
        .send({ from: account });
      const res = await setCouponPriceApi(couponPrice);
      if (res) {
        toast("Set coupon prices successfully", customToastSuccessStyle);
        setDbCouponPrice(couponPrice);
        setCouponPrice("");
      } else {
        toast("Error to save coupon price", customToastStyle);
      }
    } catch (e) {
      console.log(e);
      return toast("Error to save coupon price", customToastStyle);
    } finally {
      setCouponLoading(false);
    }
  };

  return (
    <Container style={{ marginTop: 50 }}>
      <h2>
        Manage Credits <Image src={CoinImg} />
      </h2>
      <Stack direction="vertical">
        <Stack direction="horizontal">
          <Card className="w-50 m-2">
            <Card.Body>
              <Card.Title>Credit Price</Card.Title>
              <Form.Group controlId="formFile" className="mt-3">
                <Form.Label>Price of Credit ($1 = {price} Credits)</Form.Label>
                <Form.Control
                  type="number"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                />
              </Form.Group>

              <Form.Group controlId="formFile" className="mt-3">
                <Button
                  onClick={saveCreditHandler}
                  disabled={loading}
                  style={{ minWidth: 80 }}
                >
                  {loading ? (
                    <Spinner animation="border" role="status" />
                  ) : (
                    "Save"
                  )}
                </Button>
              </Form.Group>
            </Card.Body>
          </Card>
          <Card className="w-50 m-2">
            <Card.Body>
              <Card.Title>Cost To Play TowerMaster</Card.Title>
              <Form.Group controlId="formFile" className="mt-3">
                <Form.Label>
                  Cost to play Tower Master({dbGamePrice || 0} Credits)
                </Form.Label>
                <Form.Control
                  type="number"
                  value={gamePrice}
                  onChange={(e) => setGamePrice(e.target.value)}
                />
              </Form.Group>

              <Form.Group controlId="formFile" className="mt-3">
                <Button onClick={saveGamePriceHandler} style={{ minWidth: 80 }}>
                  Save
                </Button>
              </Form.Group>
            </Card.Body>
          </Card>
        </Stack>
        <Stack direction="horizontal">
          <Card className="w-50 m-2">
            <Card.Body>
              <Card.Title>Time limit of TowerMaster</Card.Title>
              <Form.Group controlId="formFile" className="mt-3">
                <Form.Label>
                  Current time limit {dbTimeLimit} seconds
                </Form.Label>
                <Form.Control
                  type="number"
                  value={timeLimit}
                  onChange={(e) => setTimeLimit(e.target.value)}
                />
              </Form.Group>

              <Form.Group controlId="formFile" className="mt-3">
                <Button onClick={saveTimeLimitHandler} style={{ minWidth: 80 }}>
                  Save
                </Button>
              </Form.Group>
            </Card.Body>
          </Card>
          <Card className="w-50 m-2">
            <Card.Body>
              <Card.Title>Coupon price</Card.Title>
              <Form.Group controlId="formFile" className="mt-3">
                <Form.Label>
                  Price of Coupon (1 Coupon = ${dbCouponPrice})
                </Form.Label>
                <Form.Control
                  type="number"
                  value={couponPrice}
                  onChange={(e) => setCouponPrice(e.target.value)}
                />
              </Form.Group>

              <Form.Group controlId="formFile" className="mt-3">
                <Button
                  onClick={saveCouponPriceHandler}
                  style={{ minWidth: 80 }}
                >
                  {couponLoading ? "Loading..." : "Save"}
                </Button>
              </Form.Group>
            </Card.Body>
          </Card>
        </Stack>
        <Stack direction="horizontal">
          <Card className="w-50 m-2">
            <Card.Body>
              <Card.Title>Time limit of KENO</Card.Title>
              <Form.Group controlId="formFile" className="mt-3">
                <Form.Label>
                  Current time limit {dbKenoTimeLimit} minutes
                </Form.Label>
                <Form.Control
                  type="number"
                  value={kenoTimeLimit}
                  onChange={(e) => setKenoTimeLimit(e.target.value)}
                />
              </Form.Group>

              <Form.Group controlId="formFile" className="mt-3">
                <Button onClick={saveKenoTimeLimitHandler} style={{ minWidth: 80 }}>
                  Save
                </Button>
              </Form.Group>
            </Card.Body>
          </Card>
        </Stack>
      </Stack>
    </Container>
  );
}
