import axios from "axios";

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

export const createCouponApi = async (data) => {
  const res = await instance.post("coupon", data).catch(() => {
    return false;
  });

  return res?.data?.success;
};

export const editCouponApi = async (data) => {
  const res = await instance.put(`coupon`, data).catch(() => {
    return false;
  });

  return res?.data?.success;
};

export const getCouponsApi = async () => {
  const res = await instance.get("coupon").catch(() => {
    return [];
  });
  return res?.data;
};

export const createGameApi = async (data) => {
  const res = await instance.post("game", data).catch(() => {
    return false;
  });

  return res?.data?.success;
};

export const getGamesAdminApi = async () => {
  const res = await instance.get("admin/game").catch(() => {
    return [];
  });
  return res?.data;
};

export const getGamesApi = async () => {
  const res = await instance.get("game").catch(() => {
    return [];
  });
  return res?.data;
};

export const deleteGameApi = async (id) => {
  const res = await instance.delete(`game`, { data: { id } }).catch(() => {
    return false;
  });

  return res?.data?.success;
};

export const editGameApi = async (data) => {
  const res = await instance.put(`game`, data).catch(() => {
    return false;
  });

  return res?.data?.success;
};

export const getUserApi = async (address) => {
  const res = await instance.get("user?address=" + address).catch(() => {
    return { data: { address: "", coupons: {}, credit: "0", signature: "" } };
  });
  let data = res?.data;
  if (data?.coupons) {
    try {
      data.coupons = JSON.parse(data.coupons);
    } catch (e) {
      data.coupons = {};
    }
  } else {
    data["coupons"] = {};
  }

  if (data?.claimCoupons) {
    try {
      data.claimCoupons = JSON.parse(data.claimCoupons);
    } catch (e) {
      data.claimCoupons = [];
    }
  } else {
    data["claimCoupons"] = [];
  }

  if (data?.claimDiamonds) {
    try {
      data.claimDiamonds = JSON.parse(data.claimDiamonds);
    } catch (e) {
      data.claimDiamonds = [];
    }
  } else {
    data["claimDiamonds"] = [];
  }

  return data;
};

export const getSignMessageApi = async () => {
  const res = await instance.get("sign/message").catch(() => {
    return { message: "" };
  });
  return res?.data?.message;
};

export const setSignMessageApi = async ({ address, signature }) => {
  const res = await instance
    .post("sign/message", { address, signature })
    .catch(() => {
      return false;
    });
  return res?.data?.success;
};

export const deleteCouponApi = async (id) => {
  const res = await instance.delete(`coupon`, { data: { id } }).catch(() => {
    return false;
  });

  return res?.data?.success;
};

export const getCreditPriceApi = async () => {
  const res = await instance.get("coupon/price").catch(() => {
    return { price: "0" };
  });
  return res?.data?.price;
};

export const setCreditPriceApi = async (price) => {
  const res = await instance.post("coupon/price", { price }).catch(() => {
    return false;
  });
  return res?.data?.success;
};

export const getDiamondPriceApi = async () => {
  const res = await instance.get("diamond/price").catch(() => {
    return 0;
  });
  return res?.data?.price || 0;
};

export const setDiamondPriceApi = async (price) => {
  const res = await instance.post("diamond/price", { price }).catch(() => {
    return false;
  });
  return res?.data?.success;
};

export const getCostTowerMasterApi = async () => {
  const res = await instance.get("cost/towermaster").catch(() => {
    return { price: "0" };
  });
  return res?.data?.price;
};

export const setCostTowerMasterApi = async (price) => {
  const res = await instance.post("cost/towermaster", { price }).catch(() => {
    return false;
  });
  return res?.data?.success;
};

export const getTimeLimitTowerMasterApi = async () => {
  const res = await instance.get("cost/time_limit").catch(() => {
    return { price: "0" };
  });
  return res?.data?.price || "0";
};

export const getKenoTimeLimitTowerMasterApi = async () => {
  const res = await instance.get("cost/keno_time_limit").catch(() => {
    return { price: "0" };
  });
  return res?.data?.price || "0";
};

export const setTimeLimitTowerMasterApi = async (price) => {
  const res = await instance.post("cost/time_limit", { price }).catch(() => {
    return false;
  });
  return res?.data?.success;
};

export const setKenoTimeLimitTowerMasterApi = async (price) => {
  const res = await instance.post("cost/keno_time_limit", { price }).catch(() => {
    return false;
  });
  return res?.data?.success;
};

export const getCouponPriceApi = async () => {
  const res = await instance.get("price/coupon").catch(() => {
    return { price: "0" };
  });
  return res?.data?.price || "0";
};

export const setCouponPriceApi = async (price) => {
  const res = await instance.post("price/coupon", { price }).catch(() => {
    return false;
  });
  return res?.data?.success;
};

export const getSpinAvailableApi = async (wallet) => {
  const res = await instance
    .get("play/spin/available", { params: { wallet } })
    .catch(() => {
      return { available: false };
    });
  return res?.data?.available;
};

export const setSpinScoreApi = async (wallet, score) => {
  const res = await instance
    .post("play/spin", { wallet: wallet, score: score })
    .catch(() => {
      return false;
    });
  return res?.data?.success;
};

export const setPlayTowerMasterApi = async (wallet) => {
  const res = await instance
    .post("play/towermaster", { wallet: wallet })
    .catch(() => {
      return false;
    });
  return res?.data?.success;
};

export const setPlaySpinFortuneApi = async (wallet) => {
  const res = await instance
    .post("play/spin/fortune", { wallet: wallet })
    .catch(() => {
      return false;
    });
  return res?.data?.success;
};

export const setBuyCreditApi = async (wallet, amount) => {
  const res = await instance
    .post("buy/credit", { wallet: wallet, amount: amount })
    .catch(() => {
      return false;
    });
  return res?.data?.success;
};

export const setBuyDiamondApi = async (wallet, amount) => {
  const res = await instance
    .post("buy/diamond", { wallet: wallet, amount: amount })
    .catch(() => {
      return false;
    });
  return res?.data?.success;
};

export const setTowerMasterScoreApi = async ({
  level,
  delay,
  sportType,
  wallet,
}) => {
  const res = await instance
    .post("score/towermaster", { level, delay, sportType, wallet })
    .catch(() => {
      return [];
    });
  return res?.data?.coupons;
};

export const setFortuneSpinScoreApi = async ({ score, wallet, sportType }) => {
  const res = await instance
    .post("score/spin/fortune", { score, wallet, sportType })
    .catch(() => {
      return [];
    });
  return res?.data?.coupons;
};

export const buyCouponApi = async ({ wallet, couponId }) => {
  const res = await instance
    .post("buy/coupon", { wallet, couponId })
    .catch(() => {
      return { success: false };
    });
  return res?.data?.success;
};

export const putDiamondSwapRequestApi = async (data) => {
  const res = await instance.put(`tx/request`, data).catch(() => {
    return false;
  });
  return res?.data?.success;
};

export const getMyTxListApi = async (account) => {
  const res = await instance
    .get(`my-tx`, { params: { wallet: account } })
    .catch(() => {
      return [];
    });
  return res?.data;
};

export const getMyPredictionCouponListApi = async (account) => {
  const res = await instance
    .get(`my/prediction/coupon`, { params: { wallet: account } })
    .catch(() => {
      return [];
    });
  return res?.data;
};

export const getMyPredictionDiamondListApi = async (account) => {
  const res = await instance
    .get(`my/prediction/diamond`, { params: { wallet: account } })
    .catch(() => {
      return [];
    });
  return res?.data;
};

export const getPayoutListApi = async (account) => {
  const res = await instance.get(`payout`).catch(() => {
    return [];
  });
  return res?.data;
};

export const setPayoutStatusApi = async ({ id, status }) => {
  const res = await instance
    .post(`payout/status`, { id: id, status: status })
    .catch(() => {
      return { data: { success: false } };
    });
  return res?.data?.success;
};

export const swapDiamondToUsdtApi = async ({ id, wallet, amount }) => {
  const res = await instance
    .post(`swap/diamond`, { id, wallet, amount })
    .catch(() => {
      return { data: { success: false } };
    });
  return res?.data?.success;
};

export const setCouponForGameApi = async ({ id, coupons }) => {
  const res = await instance
    .post(`admin/game/score`, { id, coupons })
    .catch(() => {
      return { data: { success: false } };
    });
  return res?.data?.success;
};

export const betGameDiamondApi = async ({
  gameId,
  wallet,
  couponType,
  couponValue,
  diamondAmount,
  couponId,
}) => {
  const res = await instance
    .post(`bet/diamond`, {
      gameId,
      wallet,
      couponType,
      couponValue,
      diamondAmount,
      couponId,
    })
    .catch(() => {
      return { data: { success: false } };
    });
  return res?.data?.success;
};

export const betGameCouponApi = async ({
  gameId,
  wallet,
  couponType,
  couponValue,
  couponId,
}) => {
  const res = await instance
    .post(`bet/coupon`, {
      gameId,
      wallet,
      couponType,
      couponValue,
      couponId,
    })
    .catch(() => {
      return { data: { success: false } };
    });
  return res?.data?.success;
};

export const getBetListDiamondApi = async ({ gameId, wallet }) => {
  const res = await instance
    .get(`bet/diamond`, {
      params: {
        gameId,
        wallet,
      },
    })
    .catch(() => {
      return { data: [] };
    });

  return res?.data;
};

export const getBetListCouponApi = async ({ gameId, wallet }) => {
  const res = await instance
    .get(`bet/coupon`, {
      params: {
        gameId,
        wallet,
      },
    })
    .catch(() => {
      return { data: [] };
    });

  return res?.data;
};

export const setClaimCouponApi = async ({ wallet, betCouponId }) => {
  const res = await instance
    .post(`claim/coupon`, {
      wallet,
      betCouponId,
    })
    .catch(() => {
      return { result: false };
    });

  return res?.data?.result;
};

export const setClaimDiamondApi = async ({ wallet, betDiamondId, amount }) => {
  const res = await instance
    .post(`claim/diamond`, {
      wallet,
      betDiamondId,
      amount,
    })
    .catch(() => {
      return { result: false };
    });

  return res?.data?.result;
};
