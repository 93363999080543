import React, { useState, useMemo, useEffect } from "react";
import {
  Container,
  Button,
  Stack,
  Form,
  Offcanvas,
  Table,
} from "react-bootstrap";
import DataTable from "react-data-table-component";
import moment from "moment-timezone";

import CreateGame from "./CreateGame";
import AddScore from "./AddScore";
import {
  createGameApi,
  getGamesAdminApi,
  deleteGameApi,
  editGameApi,
} from "../../apis/coupon";

export default function GameTable() {
  const [show, setShow] = useState(false);
  const [showAddScore, setShowAddScore] = useState(false);
  const [editId, setEditId] = useState("");
  const [sports, setSports] = useState("");
  const [coming, setComing] = useState("");
  const [selectedGame, setSelectedGame] = useState({});

  const [showCoupon, setShowCoupon] = useState(false);
  const [selectedCoupons, setSelectedCoupons]: [
    selectedCoupons: any,
    setSelectedCoupons: Function
  ] = useState([]);

  const [tableData, setTableData]: [tableData: any, setTableData: Function] =
    useState([]);

  const editTableIndex = useMemo(() => {
    return tableData.findIndex((x: any) => x.id === editId);
  }, [editId]);

  const viewTableData = useMemo(() => {
    if (sports === "") return tableData;
    return tableData?.filter((item: any) => item?.sports === sports);
  }, [tableData, sports]);

  const filteredTableData = useMemo(() => {
    switch (coming) {
      case "Upcoming":
        return viewTableData?.filter(
          (item: any) =>
            new Date(item.startDate + " " + item.startTime) >= new Date()
        );
      case "Finished":
        return viewTableData?.filter(
          (item: any) =>
            new Date(item.startDate + " " + item.startTime) < new Date()
        );
      default:
        return viewTableData;
    }
  }, [viewTableData, coming]);

  useEffect(() => {
    (async () => {
      setTableData(await getGamesAdminApi());
    })();
  }, []);

  const addScoreHandler = async (data: any) => {
    setSelectedGame(data);
    setShowAddScore(true);
  };

  const columns = [
    {
      name: "Sports",
      selector: (row: any) => row.sports,
      sortable: true,
    },
    {
      name: "Home Team",
      selector: (row: any) => row.home,
      sortable: true,
    },
    {
      name: "Away Team",
      selector: (row: any) => row.away,
      sortable: true,
    },
    {
      name: "Start Date",
      selector: (row: any) => {
        const cyprus = moment.tz(
          row.startDate + " " + row.startTime,
          "Asia/Nicosia"
        );
        const tz = moment.tz.guess();
        const format = "YYYY-MM-DD HH:mm:ss";

        const localTime = cyprus.clone().tz(tz);
        const localTimeStr = localTime.format(format);
        return localTimeStr;
      },
      sortable: true,
      minWidth: "180",
    },
    {
      name: "Game Status",
      selector: (row: any) => {
        const cyprus = moment.tz(
          row.startDate + " " + row.startTime,
          "Asia/Nicosia"
        );
        const tz = moment.tz.guess();
        const format = "YYYY-MM-DD HH:mm:ss";

        const localTime = cyprus.clone().tz(tz);
        const localTimeStr = localTime.format(format);

        return new Date(localTimeStr) >= new Date() ? "Upcoming" : "Finished";
      },
      sortable: true,
    },
    {
      name: "Min Diamond",
      selector: (row: any) => row.minDiamond,
      sortable: true,
    },
    {
      name: "Max Diamond",
      selector: (row: any) => row.maxDiamond,
      sortable: true,
    },
    {
      name: "Game Type",
      selector: (row: any) => row.gameType,
      sortable: true,
    },
    {
      name: "Game Status",
      selector: (row: any) => row.game_status,
      sortable: true,
    },
    {
      name: "Coupons",
      selector: (row: any) => {
        let coupons: any = [];
        try {
          coupons = JSON.parse(row.coupons);
        } catch (e) {}
        if (coupons.length > 0) {
          return (
            <Button
              variant="outline-secondary"
              size="sm"
              style={{ fontSize: 11 }}
              onClick={() => {
                setShowCoupon(true);
                setSelectedCoupons(coupons);
              }}
            >
              View All
            </Button>
          );
        }
        return null;
      },
      sortable: true,
    },
    {
      name: "Edit",
      selector: (row: any) => (
        <Button
          variant="primary"
          size="sm"
          style={{ fontSize: 11 }}
          onClick={() => editItem(row.id)}
          disabled={row.game_status}
        >
          Edit
        </Button>
      ),
      sortable: false,
    },
    {
      name: "Delete",
      selector: (row: any) => (
        <Button
          style={{ fontSize: 11 }}
          variant="danger"
          size="sm"
          onClick={() => deleteItem(row.id)}
        >
          Delete
        </Button>
      ),
      sortable: false,
    },
    {
      name: "Add Score",
      selector: (row: any) =>
        new Date(row.startDate + " " + row.startTime) < new Date() ? (
          <Button
            variant="success"
            size="sm"
            style={{ fontSize: 11 }}
            onClick={() => addScoreHandler(row)}
          >
            Add Score
          </Button>
        ) : null,
      sortable: false,
    },
  ];

  const deleteItem = async (id: string) => {
    var sure = window.confirm("Are you sure you want to delete this item");
    if (!sure) return;
    await deleteGameApi(id);

    const filteredData = tableData.filter((item: any) => item?.id !== id);
    setTableData(filteredData);
  };

  const editItem = (id: string) => {
    setEditId(id);
    setShow(true);
  };

  const saveGame = async (info: any) => {
    const _info: any = {
      sports: info?.sports,
      home: info?.home,
      away: info?.away,
      startDate: info?.startDate,
      startTime: info?.startTime,
      gameType: info?.gameType,
      minDiamond: info?.minDiamond,
      maxDiamond: info?.maxDiamond,
    };

    if (editId) {
      await editGameApi({ ...info, ...{ id: editId } });
      setTableData(await getGamesAdminApi());
    } else {
      await createGameApi(info);
      setTableData([_info].concat(tableData));
    }
  };

  return (
    <Container>
      <Offcanvas show={showCoupon} onHide={() => setShowCoupon(false)}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Coupons</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>#</th>
                <th>Coupon Type</th>
                <th>Coupon Value</th>
                <th>Payout</th>
              </tr>
            </thead>
            <tbody>
              {selectedCoupons.map((coupon: any, index: number) => {
                return (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{coupon.couponType}</td>
                    <td>{coupon.couponValue}</td>
                    <td>{coupon.payout}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Offcanvas.Body>
      </Offcanvas>

      <AddScore
        show={showAddScore}
        data={selectedGame}
        onHide={() => setShowAddScore(false)}
        saveScore={() => {}}
      />

      <CreateGame
        show={show}
        data={tableData[editTableIndex] || {}}
        onHide={() => setShow(false)}
        saveGame={saveGame}
      />

      <Stack
        direction="horizontal"
        className="w-100 justify-content-md-between mt-5"
      >
        <Stack direction="horizontal">
          <Form.Select
            aria-label="Sports"
            onChange={(e) => {
              setSports(e.target.value);
            }}
            style={{ maxWidth: 150 }}
            defaultValue={sports}
          >
            <option value="">All</option>
            <option value="Football">Football</option>
            <option value="NBA Basketball">NBA Basketball</option>
            <option value="Other Basketball">Other Basketball</option>
            <option value="Cricket">Cricket</option>
          </Form.Select>
          <Form.Select
            aria-label="Sports"
            onChange={(e) => {
              setComing(e.target.value);
            }}
            style={{ maxWidth: 150, marginLeft: 10 }}
            defaultValue={coming}
          >
            <option value="">All</option>
            <option value="Upcoming">Upcoming</option>
            <option value="Finished">Finished</option>
          </Form.Select>
        </Stack>
        <h2>Manage Games</h2>
        <Button
          variant="success"
          onClick={() => {
            setShow(true);
            setEditId("");
          }}
        >
          Add Game
        </Button>
      </Stack>
      <Stack className="mt-4">
        <DataTable columns={columns} data={filteredTableData} pagination />
      </Stack>
    </Container>
  );
}
