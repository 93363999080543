import { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Table from "react-bootstrap/Table";
import Image from "react-bootstrap/Image";
import Container from "react-bootstrap/Container";
import { useNavigate } from "react-router-dom";

import { AiOutlineRedo, AiOutlineLogout, AiTwotoneFlag } from "react-icons/ai";

import GameOverImg from "../../assets/img/game-over.gif";

import { store } from "../../store";

export default function GameOver({
  show,
  winningCoupons,
  playAgain,
}: {
  show: boolean;
  winningCoupons: any;
  playAgain: () => void;
}) {
  const nav = useNavigate();
  const [internalShow, setInternalShow] = useState(false);

  const [account] = store.useState("account");

  useEffect(() => {
    if (show) {
      setTimeout(() => {
        setInternalShow(true);
      }, 6000);
    }
  }, [show]);

  const playAgainHandler = () => {
    playAgain();
    setInternalShow(false);
  };

  const exitHandler = () => {
    nav("/dashboard");
  };

  const makePredictionHandler = () => {
    nav("/games");
  };

  return (
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={internalShow}
    >
      <Modal.Header></Modal.Header>
      <Modal.Body style={{ position: "relative" }}>
        <Container>
          <Image src={GameOverImg} alt="" fluid />
        </Container>
        <Container
          style={{
            display: "flex",
            justifyContent: "space-around",
            position: "absolute",
            top: 450,
            zIndex: 9999999,
          }}
        >
          <Button style={{ minWidth: 100 }} onClick={playAgainHandler}>
            Play Again
            <AiOutlineRedo style={{ marginLeft: 5 }} size={18} />
          </Button>
          {account &&
            ((
              <Button style={{ minWidth: 100 }} onClick={exitHandler}>
                Exit
                <AiOutlineLogout style={{ marginLeft: 5 }} size={18} />
              </Button>
            ) as any)}
          {account && (
            <Button style={{ minWidth: 100 }} onClick={makePredictionHandler}>
              Make Prediction
              <AiTwotoneFlag style={{ marginLeft: 5 }} size={18} />
            </Button>
          )}
        </Container>
        <Container style={{ marginTop: 20 }}>
          <h5>
            You won {winningCoupons.length}{" "}
            {winningCoupons.length > 1 ? "coupons" : "coupon"}
          </h5>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>No</th>
                <th>Sport</th>
                <th>Coupon Type</th>
                <th>Value</th>
              </tr>
            </thead>
            <tbody>
              {winningCoupons.map((item: any, index: number) => {
                return (
                  <tr>
                    <td>{index + 1}</td>
                    <td>{item["Sport"]}</td>
                    <td>{item["Coupon Type"]}</td>
                    <td>{item["Value"]}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Container>
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
}
