import React, { useMemo } from "react";
import { Container } from "react-bootstrap";
import { useNavigate, useParams, Link } from "react-router-dom";

export default function Menu() {
  const navigate = useNavigate();
  const params = useParams();
  const page_title = useMemo(() => {
    if (!params.sub_url) {
      return;
    }

    switch (params.sub_url) {
      case "create_proposal":
        return `Create Proposal`;
      case "create_commission":
        return `Create Commission`;
      case "create_vest":
        return `Create Task`;
      case "swaps":
        return `DAO Swap Lists`;
      case "vests":
        return `Work Proposals`;
      case "stake":
        return `Stake LOP token`;
      default:
        return `DAO ${
          params.sub_url?.[0].toUpperCase() + params.sub_url.slice(1)
        }`;
    }
  }, [params.sub_url]);
  return (
    <Container className="d-flex align-items-center justify-content-center mt-5">
      <div className="sub-link">
        <Link
          to="/admin/games"
          style={{ color: params.sub_url === "games" ? "#2cffc6" : "#fff" }}
        >
          Manage Games
        </Link>
        <Link
          to="/admin/credits"
          style={{ color: params.sub_url === "credits" ? "#2cffc6" : "#fff" }}
        >
          Manage Credits
        </Link>
        <Link
          to="/admin/diamonds"
          style={{ color: params.sub_url === "diamonds" ? "#2cffc6" : "#fff" }}
        >
          Manage Diamonds
        </Link>
        <Link
          to="/admin/coupons"
          style={{
            color:
              params.sub_url === "coupons" || params.sub_url === "add_coupons"
                ? "#2cffc6"
                : "#fff",
          }}
        >
          Manage Coupons
        </Link>
        <Link
          to="/admin/payout"
          className="border-0"
          style={{
            color: params.sub_url === "payout" ? "#2cffc6" : "#fff",
          }}
        >
          Payout
        </Link>
      </div>
    </Container>
  );
}
